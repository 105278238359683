<template>
    <div>
        <treeTable :data="RoadTreeDataList" :columns='columns' border tree-type :is-fold='false' :selection-type='false' :show-index='false' :expand-type='false'>
            <template slot="handleEvent" slot-scope="scope">
                    <el-button @click="AddClick(scope.row)" type="success" size="small" icon="el-icon-circle-plus-outline"></el-button>
                    <el-button v-if='scope.row.road_id>0' @click="EditClick(scope.row)" type="primary" size="small" icon="el-icon-edit"></el-button>
                    <el-button v-if='scope.row.road_id>0' @click="DelClick(scope.row)" type="danger" size="small" icon="el-icon-delete"></el-button>
            </template>
        </treeTable>

        <el-dialog :title="title" :visible.sync="dialogVisible" width='30%' :append-to-body='true'>
             <el-form :model="roadForm" :rules="rules" ref="roadForm" label-width="120px" size='medium'>
            <el-form-item label="路段名称：" prop="road_name">
                <el-input v-model="roadForm.road_name"></el-input>
            </el-form-item>
            <el-form-item label="所属路段" >
                <el-select v-model="roadForm.road_sub_id" style="width:100%" :disabled="roadForm.road_id===0?false:true">
                    <el-option v-for="item in DataList" :key="item.road_id" :value="item.road_id" :label="item.road_name"></el-option>
                    <el-option  :value="0" label=" "></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注：">
                <el-input v-model="roadForm.road_remark"></el-input>
            </el-form-item>
        </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import util from '../../util/util'
import treeTable from 'vue-table-with-tree-grid'

export default {
  data: function () {
    return {
      RoadTreeDataList: [],
      DataList: [],
      roadForm: {
        road_id: 0,
        road_sub_id: 0,
        road_remark: '',
        road_name: ''
      },
      columns: [
        {
          label: '路段名',
          prop: 'road_name'
        },
        {
          label: '备注',
          prop: 'road_remark'
        },
        {
          label: '操作',
          type: 'template',
          template: 'handleEvent'
        }
      ],
      title: '',
      dialogVisible: false,
      rules: {
        road_name: [{ required: true, message: '请输入路段名称', trigger: 'blur' }]
      }
    }
  },
  components: {
    treeTable
  },
  mounted () {
    this.GetDataList()
  },
  methods: {
    GetDataList () {
      util.Post('road/getroaddatalist').then(res => {
        if (res.rpStatus === 10006) {
          this.DataList = res.list
          var arr = []
          var item = {}
          item.road_name = '路段管理'
          item.road_id = 0
          item.road_sub_id = null
          arr.push(item)
          res.list.forEach(item => {
            arr.push(item)
          })

          var data = JSON.stringify(arr)
          var json = JSON.parse(data)

          this.RoadTreeDataList = util.FommatTreeData(json, 'road_sub_id', 'road_id')
        }
      })
    },
    AddClick (row) {
      this.dialogVisible = true
      this.title = '新增'
      this.roadForm.road_id = 0
      this.roadForm.road_sub_id = row.road_id
      this.roadForm.road_remark = ''
      this.roadForm.road_name = ''
    },
    EditClick (row) {
      this.dialogVisible = true
      this.title = '修改'
      this.roadForm = JSON.parse(JSON.stringify(row))
    },
    submit () {
      this.$refs.roadForm.validate((valid) => {
        if (valid) {
          if (this.roadForm.road_sub_id === undefined) { this.roadForm.road_sub_id = 0 }
          util.Post('road/edit', JSON.stringify(this.roadForm)).then(res => {
            if (res.rpStatus === 10006) {
              this.GetDataList()
              this.dialogVisible = false
            } else { this.$message.error(res.rpMsg) }
          })
        }
      })
    },
    getChildren (data) {
      var arr = []
      if (data && data.length > 0) {
        data.forEach((item, index) => {
          if (item.children) {
            this.getChildren(item.children)
          } else {
            if (item.type === 'student') {
              arr.push(item)
            }
          }
        })
      }
      return arr
    },
    DelClick (row) {
      if (row.children) {
        this.$message.error('该路段下有其他子路段信息，禁止删除')
        return
      }
      this.$confirm('确定删除路段“' + row.road_name + '”？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.roadForm.rs_id = row.rs_id
        this.roadForm.rs_name = row.rs_name
        util.Post('road/Del', JSON.stringify(this.roadForm)).then(res => {
          if (res.rpStatus === 10006) { this.GetRoadDataList() } else { this.$message.error('删除路段信息失败，' + util.ErrorMsg(res.rpStatus)) }
        })
      }).catch(e => e)
    }
  }
}
</script>

<style>

</style>
